import { Element } from './Element';
import { Location } from './index';
import { TRAVEL_ACCOMODATION } from '../../constants/travel';

class Accommodation extends Element {
  constructor(init) {
    super({ ...init, type: TRAVEL_ACCOMODATION });

    const defaults = {
      location: {},
      amount: null,
      amount_currency: null,
      arrival_at: null,
      departure_at: null,
      wifi: false,
      breakfast: true,
      standard: 0,
      calculated_amount: null,
      calculated_amount_currency: null,
      searcher_disabled: false,
      request_travelers: [],
      rooms: [],
      extraServicesChecked: false,
      extraServicesText: null,
      parking: false,
      refundable: true,
      // round_trip: true,
    };

    const opts = Object.assign({}, defaults, init);
    let travelers = opts.request_travelers;
    if (!travelers.length && opts.travelers && opts.travelers.length) {
      travelers = opts.travelers.map((item) => item.slug);
    }

    let rooms = opts.rooms;
    if (!rooms.length && opts.room_allocations && opts.room_allocations.length) {
      rooms = opts.room_allocations.map((item) => item.capacity);
    }

    this.location = new Location(opts.location);
    this.amount = opts.amount;
    this.amount_currency = opts.amount_currency;
    this.arrival_at = opts.arrival_at;
    this.departure_at = opts.departure_at;
    this.wifi = opts.wifi;
    this.breakfast = opts.breakfast;
    this.standard = opts.standard;
    this.calculated_amount = opts.calculated_amount;
    this.calculated_amount_currency = opts.calculated_amount_currency;
    this.searcher_disabled = opts.searcher_disabled;
    this.request_travelers = travelers;
    this.rooms = rooms;
    // this.round_trip                 = opts.round_trip;
    this.extraServicesChecked = opts.extraServicesChecked;
    this.extraServicesText = opts.extraServicesText;
    this.parking = opts.parking;
    this.refundable = opts.refundable;
  }

  getStartLocation() {
    return this.location;
  }

  getEndLocation() {
    return this.location;
  }

  getStartDate() {
    return this.arrival_at;
  }

  getEndDate() {
    return this.departure_at;
  }

  getAllAmounts() {
    return [
      {
        currency: this.amount_currency,
        value: this.amount,
      },
    ];
  }
}

export { Accommodation };
export default Accommodation;
